import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";
import MilestoneForm from "../../components/milestones/MilestoneForm";
import AdminTable from "../../components/common/AdminTable";
import { MilestonesContext } from "../../context/MilestonesContext";
import { navigate } from "@reach/router";

const AdminMilestones = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { milestones, getMilestones } = useContext(MilestonesContext);

  useEffect(() => {
    getMilestones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateEdit = (milestone) => {
    const milestone_id = milestone?.milestone_id
      ? milestone.milestone_id
      : null;
    modalComponent(
      `${milestone_id !== null ? "Editar" : "Agregar"} Nivel`,
      <MilestoneForm milestone_id={milestone_id} handleCancel={clearModal} />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Niveles" onClick={handleCreateEdit} />
      <div className="card shadow p-3">
        <AdminTable
          viewRow={({ milestone_id }) => navigate(`/myadmin/milestones/${milestone_id}`)}
          headers={["Nombre", "Descripcion", "Min Streaks"]}
          editRow={handleCreateEdit}
          rows={milestones}
          exclude={[
            "min_purchase_amount",
            "milestone_benefits",
            "min_reservations",
            "expiration_days",
            "reward_classes",
            "min_purchases",
            "milestone_id",
            "min_videos",
            "createdAt",
            "updatedAt",
            "deletedAt",
            "file_id",
          ]}
        />
      </div>
    </div>
  );
};

export default AdminMilestones;
