import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import AdminTable from "../../components/common/AdminTable";
import { MilestonesContext } from "../../context/MilestonesContext";
import { MilestoneBenefitsContext } from "../../context/MilestoneBenefitsContext";
import MilestoneBenefitForm from "../../components/milestones/MilestoneBenefitForm";
import { ModalContext } from "../../context/ModalContext";
import ConfirmDelete from "../../components/global/ConfirmDelete";

const AdminSingleMilestone = ({ milestone_id }) => {
  const { milestone, getSingleMilestone } = useContext(MilestonesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { milestone_benefits, getMilestoneBenefits, deleteMilestoneBenefit } =
    useContext(MilestoneBenefitsContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone_id]);

  const fetchData = () => {
    getSingleMilestone(milestone_id);
    getMilestoneBenefits({ milestone_id });
  };

  const handleDelete = (milestone) => {
    modalComponent(
      "Eliminar Beneficio",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar el beneficio ${milestone.name}?`}
        handleCancel={clearModal}
        handleDelete={() =>
          deleteMilestoneBenefit(milestone.milestone_benefit_id, fetchData)
        }
      />
    );
  };

  const handleCreateEdit = (milestone_benefit) => {
    const milestone_benefit_id =
      milestone_benefit?.milestone_benefit_id || null;
    console.log(milestone_benefit_id);
    modalComponent(
      `${milestone_benefit_id !== null ? "Editar" : "Agregar"} Beneficio`,
      <MilestoneBenefitForm
        milestone_benefit_id={milestone_benefit_id}
        handleCancel={clearModal}
      />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title={`Beneficios ${
          milestone && milestone !== null ? milestone.name : "Nivel"
        }`}
        onClick={handleCreateEdit}
      />
      <div className="card shadow p-3">
        <AdminTable
          editRow={handleCreateEdit}
          deleteRow={handleDelete}
          rows={milestone_benefits}
          headers={["Nombre", "Tipo", "Valor"]}
          exclude={[
            "milestone_benefit_id",
            "milestone_id",
            "createdAt",
            "updatedAt",
            "deletedAt",
            "file_id",
            "file",
          ]}
        />
      </div>
    </div>
  );
};

export default AdminSingleMilestone;
